<template>
  <div id="add-new-product">
    <div class="row">
        <div class="col-lg-8">
            <div class="card">
                <div class="card-body">
                  <p class="sub-header">Fill all information below</p>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label for="product-name">
                                    Product Name
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="text" v-validate="{ required: true}" v-model="data.name" id="product-name" name="name" class="form-control" :class="{ 'is-invalid': errors.has('name') }" placeholder="e.g : 3D Logo Design" />
                                <span class="text-danger invalid-feedback"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label for="slug">
                                    Slug
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="text" v-model="data.slug" v-validate="{ required: true, regex: /^[a-z0-9-_]+$/}" id="slug" class="form-control" name="slug" :class="{ 'is-invalid': errors.has('slug')}" placeholder="e.g : 3d-logo-design" />
                                <span class="text-danger invalid-feedback" v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-3">
                                <label for="product-price">
                                    Price (dollar)
                                    <span class="text-danger">*</span>
                                </label>
                                <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.price" class="form-control" name="price" id="product-price" placeholder="Enter Price" />
                                <span class="text-danger invalid-feedback" v-show="errors.has('price')">{{ errors.first('price') }}</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-3">
                            <label class="">Category</label>
                            <select class="custom-select" v-validate="{required: true}" v-model="data.category_id" name="category" placeholder="--select category--">
                                <option disabled value="" key="-1">-- choose category--</option>
                                <option :value="category.id" v-for="(category, index) in categoryList" :key="index">{{category.name}}</option>
                            </select>
                            <span class="text-danger invalid-feedback"  v-show="errors.has('category')">{{ errors.first('category') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-3">
                        <b-form-checkbox v-model="data.is_featured" name="is-featured" switch>
                          Featured Product
                        </b-form-checkbox>
                    </div>
                    <p class="sub-header mb-2">Upload product image</p>
                    <vue-dropzone id="productImageDrop" ref="productImageDrop"
                    :use-custom-slot="true" :maxFiles="1">
                        <div class="dz-message needsclick">
                            <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                            <h3>Product Image</h3>
                            <span class="text-muted font-13">
                                (Dimension should be 500 x 300 Max: 500kb)
                            </span>
                        </div>
                    </vue-dropzone>
                    <div class="form-group text-right mt-3 m-b-0">
                      <button class="btn btn-primary" @click.prevent="submitForm" type="button">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>
<script>
import VueDropzone from '../../components/VueDropzone.vue';

export default{
  components: { VueDropzone },
  data() {
    return {
      data: {
        name: "",
        slug: "",
        category_id:"",
        is_featured: false,
        price: "",
      },
    }
  },
  watch: {
    'data.name': function (name){
      this.data.slug = name.toString().normalize('NFD').replace(/[\u0300-\u036f]/g,'').replace(/\s+/g,'-').toLowerCase().replace(/&/g,'-and-').replace(/[^a-z0-9\-]/g,'').replace(/-+/g,'-').replace(/^-*/,'').replace(/-*$/,'');
    },
  },
  computed: {
    categoryList() {
      return this.$store.state.categoryList.categories.map((cat) => {
        return {id:cat.id, name:cat.name} });
    },
  },
  methods: {
    submitForm() {
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form Not Valid"); return;}
        if(!this.collectFiles().length){this.alertError("Product Photo Required"); return;}
        const formData = new FormData()
        formData.append('image_file', this.collectFiles()[0])
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$store.dispatch("productList/addProduct", formData)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.resetForm()
          }
        })
      });
    },
    collectFiles(){
      var arrafile = []
      if(this.$refs.productImageDrop){
        arrafile = this.$refs.productImageDrop.getAcceptedFiles();
      }
      return arrafile
    },
    resetForm() {
      this.data = {
        name: "",
        slug: "",
        category_id:null,
        is_featured: false,
        price: "",
      }
      this.$refs.productImageDrop.removeAllFiles();
      this.$validator.reset()
    }
  },
  created(){
    this.$store.dispatch("categoryList/fetchCategories")
  }
}
</script>
